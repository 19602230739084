import Vue from 'vue'
import cookieSetting from '~/plugins/cookieSetting.js'

Vue.use(cookieSetting)

Vue.mixin({
  data() {
    return {
      authenToken: null,
      loginStatus: false,
      userFullname: '',
      openForCheckout: true,
      loading_cart: false,
      disablePackUnit: false,
      itemNo: 0
    }
  },
  beforeMount() {
    if (this.$cookies.get('userAuthenToken') !== undefined) {
      this.loginStatus = true
      this.authenToken = this.$cookies.get('userAuthenToken')
    } else {
      this.loginStatus = false
      this.authenToken = null
    }

    // Disable For Pack Unit
    this.disablePackUnit = true
  },
  mounted() {},
  methods: {
    redirectPage(page, paramsFormLink = '') {
      window.location.href = page + paramsFormLink
    },
    redirectPageHref(link) {
      window.location.href = link
    },
    showAlertMsg(message) {
      this.$bvModal.msgBoxOk(message, {
        title: 'Alert',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 text-center',
        bodyClass: 'text-center text-danger',
        footerClass: 'p-2 text-center',
        centered: true
      })
    },
    showAlertMsgWithCallback(message, callback = null) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Alert',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 text-center',
          bodyClass: 'text-center text-danger',
          footerClass: 'p-2 text-center',
          centered: true
        })
        .then((value) => {
          if (typeof callback === 'function') {
            callback()
          }
        })
    },
    showAlertMsgWithGoToLineAdd(message, callback = null) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Alert',
          size: 'md',
          buttonSize: 'md',
          okTitle: 'ADD LINE',
          okVariant: 'success',
          headerClass: 'p-2 text-center',
          bodyClass: 'text-center text-danger',
          footerClass: 'p-2 text-center',
          centered: true,
          hideHeaderClose: false
        })
        .then((value) => {
          if (typeof callback === 'function') {
            callback()
          }
        })
    },
    showOkMsg(message, callback = null) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk(message, {
          title: '',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'text-center text-success',
          footerClass: 'p-2 border-top-0 text-center',
          centered: true
        })
        .then((value) => {
          if (typeof callback === 'function') {
            callback()
          }
        })
    },

    isNumberOnly(evtget, valueItem = null) {
      let evt = null
      if (evt) {
        evt = evtget
      } else {
        evt = window.event
      }
      //   if (valueItem !== null && valueItem.length >= 10) {
      //     evt.preventDefault()
      //   } else {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
      //   }
    },
    filterNumberOnly(evtget) {
      let evt = null
      if (evt) {
        evt = evtget
      } else {
        evt = window.event
      }
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    openModalMarketingConsent() {
      if (this.authenToken !== null) {
        this.$bvModal.show('marketing-consent-modal-only')
      } else {
        this.showAlertMsg(
          'กรุณาเข้าสู่ระบบก่อนตั้งค่าความยินยอมรับข่าวสารและสิทธิประโยชน์'
        )
      }
    },

    checkOutOfstockStatus(ListOfProducts) {
      if (ListOfProducts !== undefined && ListOfProducts.length > 0) {
        ListOfProducts.forEach((itemProduct, index) => {
          let outOfStockStatus = true
          itemProduct.colors.forEach((itemColor) => {
            if (itemColor.product_status !== 'สินค้าหมด') {
              outOfStockStatus = false
            }
          })
          ListOfProducts[index].outOfStockStatus = outOfStockStatus
        })
      }
      return ListOfProducts
    },
    calDiscountOfProductList(ListOfProducts) {
      if (ListOfProducts !== undefined && ListOfProducts.length > 0) {
        ListOfProducts.forEach((itemProduct, index) => {
          itemProduct.price = this.genPricePerType(itemProduct)
          itemProduct.full_price = this.genPriceBeforeDiscountPerType(
            itemProduct
          )
          itemProduct.discount_price = this.genDiscountPricePerType(itemProduct)
          itemProduct.discount_unit = this.genDiscountUnitPerType(itemProduct)
          itemProduct.discount_percent = this.genDiscountPercentPerType(
            itemProduct
          )

          let showDiscountAllProduct = false
          if (
            !(itemProduct.discount_unit && itemProduct.discount_percent > 0)
          ) {
            let percentDiscountAllProduct = 0
            let priceAfterDiscount = itemProduct.price

            if (
              itemProduct.wholesale_volume != null &&
              itemProduct.wholesale_discount > 0 &&
              this.$cookies.get('orderFromPartner') !== 9 //* Makro Session */
            ) {
              // check type of discount
              showDiscountAllProduct = true

              // check type of discount
              switch (itemProduct.wholesale_type) {
                case '%':
                  percentDiscountAllProduct = itemProduct.wholesale_discount
                  break
                default:
                  // currency by default
                  percentDiscountAllProduct =
                    (priceAfterDiscount / itemProduct.wholesale_discount) * 100
                  break
              }

              priceAfterDiscount =
                priceAfterDiscount -
                priceAfterDiscount * (percentDiscountAllProduct / 100)
            }
            ListOfProducts[
              index
            ].showDiscountAllProduct = showDiscountAllProduct

            ListOfProducts[
              index
            ].percentDiscountAllProduct = percentDiscountAllProduct

            ListOfProducts[index].priceAfterDiscount =
              Math.round(priceAfterDiscount * 100) / 100
          }
        })
      }
      return ListOfProducts
    },
    goToAddLINE() {
      if (this.$cookies.get('orderFromPartner') === 9) {
        window.location.href = 'https://lin.ee/DeWKZk5'
      } else {
        window.location.href = 'https://lin.ee/K0PgOze'
      }
    },
    async sendLineNotifyMsg(userId, msg, imageUrl = null, imgFile = null) {
      const formData = new FormData()
      formData.append('message', msg)
      formData.append('userId', userId)

      if (imageUrl !== null) {
        formData.append('imageUrl', imageUrl)
      }
      if (imgFile !== null) {
        formData.append('imgFile', imgFile)
      }

      await this.$axios.post('api/send-line-notify/', formData)
      return true
    },
    addCartToDB(cartKey, cartValue, callback = null) {
      const formData = new FormData()
      formData.append('authenToken', this.$cookies.get('userAuthenToken'))
      formData.append('cartKey', cartKey)
      formData.append('cartValue', cartValue)
      this.$axios
        .post('/api/add-cart/', formData)
        .then(() => {
          this.$root.loading_cart = false
          if (callback !== null) {
            callback()
          }
        })
        .catch(() => {
          this.$root.loading_cart = false
          if (callback !== null) {
            callback()
          }
        })
    },
    updateCartToDB(cartKey, cartValue, callback = null) {
      const formData = new FormData()
      formData.append('authenToken', this.$cookies.get('userAuthenToken'))
      formData.append('cartKey', cartKey)
      formData.append('cartValue', cartValue)
      this.$axios
        .post('/api/update-cart/', formData)
        .then(() => {
          this.$root.loading_cart = false
          if (callback !== null) {
            callback()
          }
        })
        .catch(() => {
          this.$root.loading_cart = false
          if (callback !== null) {
            callback()
          }
        })
    },
    updateAllItemCartToDB(cartItemArr, callback = null) {
      const formData = new FormData()
      formData.append('authenToken', this.$cookies.get('userAuthenToken'))
      formData.append('cartItemList', JSON.stringify(cartItemArr))
      this.$axios
        .post('/api/update-all-item-cart/', formData)
        .then(() => {
          if (callback !== null) {
            callback()
          }
        })
        .catch(() => {
          if (callback !== null) {
            callback()
          }
        })
    },
    async getCartFromDB(callback = null) {
      const formData = new FormData()
      let response = null
      formData.append('authenToken', this.$cookies.get('userAuthenToken'))
      await this.$axios.post('/api/get-cart/', formData).then((res) => {
        response = res.data
      })
      callback(response)
    },
    myDateFormat(d) {
      return (
        d.getFullYear() +
        '/' +
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + d.getDate()).slice(-2) +
        ' ' +
        ('0' + d.getHours()).slice(-2) +
        ':' +
        ('0' + d.getMinutes()).slice(-2) +
        ':' +
        ('0' + d.getSeconds()).slice(-2)
      )
    },

    genUnitTypeText(item) {
      let unit = ''
      if (
        +item.unit_sell_box === '1' ||
        +item.unit_sell_pack === '1' ||
        +item.unit_sell_piece === '1'
      ) {
        unit = 'ชิ้น'
      } else {
        unit = 'กก.'
      }
      return unit
    },
    genPricePerType(item) {
      let price = ''
      if (+item.unit_sell_box === 1) {
        if (+item.unit_sell_pack === 1) {
          price = item.price_per_pack
        } else {
          price = item.price_per_box
        }
      } else if (+item.unit_sell_pack === 1) {
        price = item.price_per_pack
      } else if (+item.unit_sell_piece === 1) {
        price = item.price_per_piece
      } else if (+item.unit_sell_kilogram === 1) {
        price = item.price_per_kilogram
      }
      return price
    },
    genPriceBeforeDiscountPerType(item) {
      let priceBeforeDiscount = ''
      if (+item.unit_sell_box === 1) {
        if (+item.unit_sell_pack === 1) {
          priceBeforeDiscount = item.full_price_per_pack
        } else {
          priceBeforeDiscount = item.full_price_per_box
        }
      } else if (+item.unit_sell_pack === 1) {
        priceBeforeDiscount = item.full_price_per_pack
      } else if (+item.unit_sell_piece === 1) {
        priceBeforeDiscount = item.full_price_per_piece
      } else if (+item.unit_sell_kilogram === 1) {
        priceBeforeDiscount = item.full_price_per_kilogram
      }
      return priceBeforeDiscount
    },
    genDiscountPricePerType(item) {
      let discountPrice = ''
      if (+item.unit_sell_box === 1) {
        if (+item.unit_sell_pack === 1) {
          discountPrice = item.discount_price_per_pack
        } else {
          discountPrice = item.discount_price_per_box
        }
      } else if (+item.unit_sell_pack === 1) {
        discountPrice = item.discount_price_per_pack
      } else if (+item.unit_sell_piece === 1) {
        discountPrice = item.discount_price_per_piece
      } else if (+item.unit_sell_kilogram === 1) {
        discountPrice = item.discount_price_per_kilogram
      }
      return discountPrice
    },
    genDiscountUnitPerType(item) {
      let discountUnit = ''
      if (+item.unit_sell_box === 1) {
        if (+item.unit_sell_pack === 1) {
          discountUnit = item.discount_unit_per_pack
        } else {
          discountUnit = item.discount_unit_per_box
        }
      } else if (+item.unit_sell_pack === 1) {
        discountUnit = item.discount_unit_per_pack
      } else if (+item.unit_sell_piece === 1) {
        discountUnit = item.discount_unit_per_piece
      } else if (+item.unit_sell_kilogram === 1) {
        discountUnit = item.discount_unit_per_kilogram
      }
      return discountUnit
    },
    genDiscountPercentPerType(item) {
      let discountPercent = ''
      if (+item.unit_sell_box === 1) {
        if (+item.unit_sell_pack === 1) {
          discountPercent = item.discount_percent_per_pack
        } else {
          discountPercent = item.discount_percent_per_box
        }
      } else if (+item.unit_sell_pack === 1) {
        discountPercent = item.discount_percent_per_pack
      } else if (+item.unit_sell_piece === 1) {
        discountPercent = item.discount_percent_per_piece
      } else if (+item.unit_sell_kilogram === 1) {
        discountPercent = item.discount_percent_per_kilogram
      }
      return discountPercent
    }
  }
})

const numeral = require('numeral')
Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})
